/**
 * Created by nick on 1/9/2020.
 */
import React from "react"
import {Link} from "gatsby"

import QuestionLayout from "../components/questionlayout"
import QuestionContent from "../components/questioncontent"
import SEO from "../components/seo"
import progress1 from "../images/progress_bar_1.png"
import progress2 from "../images/progress_bar_2.png"
import progress3 from "../images/progress_bar_3.png"
import progress4 from "../images/progress_bar_4.png"
import progress5 from "../images/progress_bar_5.png"
import progress6 from "../images/progress_bar_6.png"


const Questions1 = () => (
    <QuestionLayout>
        <div className="container">
            <div className="question-header question-header-one is-vertical-center content-centered">
                Take This Short Quiz to find out
            </div>
            <div className="question">
                <h3>Do you drink coffee throughout the day?</h3>
            </div>
            <div className="progress-image">
                <progress className="progress is-primary" value="15" max="100"></progress>
            </div>
            <div className="columns is-centered">

                <div className="questions column is-half-desktop">
                    <a href="/questions2/?answer=1" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;Yes 1-2
                        cups</a>
                    <a href="/questions2/?answer=2" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;Yes 3-4
                        cups</a>
                    <a href="/questions2/?answer=3" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;Yes 5+
                        cups</a>
                    <a href="/questions2/?answer=4" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;No</a>
                </div>

            </div>

            <div className="content-spacer">
                . <br/>

                </div>
        </div>

    </QuestionLayout>
)

export default Questions1

function getSiteURL() {
    const site = process.env.GATSBY_SITE;
    return site;
}


